import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2020-03-16T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`History is a record of humanity trying to get a grip.`}</strong>{` It's completely relative. Things were always this bad, but they were also, always this good.`}</p>
    <p>{`Read: New York 2140, Kim Stanley Robinson`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      